<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" src="@/assets/jsfn.png" alt="" />
      <div class="jsfnB1">物联网</div>
      <div class="jsfnC1">IOT</div>
      <div class="jsfnD1">
        &emsp;&emsp;物联网是基于互联网、传统电信网等的信息承载体，也是一个与传统行业紧密耦合的产业，能助力传统产业在既有优势的基础上进一步升级。
        制造业是工业互联网的主要应用领域。然茜科技积极探寻物联网对于传统行业的赋能，
        共同开启“万物互联”的新时代。
      </div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">物联网+工业制冷云</div>
        <div class="bodyTitle">
          <div class="product">IOT+ INDUSTRIAL COLD CLOUD</div>
          <div class="more">
            <div class="gdal">查看详情</div>
            <div>></div>
          </div>
        </div>
      </div>
      <div>
        <div class="jsfny11">
          <img class="jsfny1" src="@/assets/jsfni1.png" alt="" />
        </div>
        <div class="fontA1s">
          &emsp;&emsp;工业制冷云平台以冷库（冻库）行业为基础，结合冷库的实际需求与特点，运用物联网技术，制定工业制冷解决方案，进一步提高冷库的运行效率、
          降低运营成本。实现远程控制和APP智能控制，为冷库的正常运行和管理提供有力保障
        </div>
        <div class="fontA1">
          1、实时精确监控：通过组态监控，视频监控及数据监控，
          平台可以针对设备运行情况、设备相关资料、现场冷库及设备的实时画面或者根据设备和库房为单位进行实时监控。
        </div>
        <div class="fontA1">
          2、全自动高效控制管理：平台可以根据设备或库房对应参数数据指标进行远程控制。
        </div>
        <div class="fontA1">
          3、资产数字化管理：通过平台可以直观的看到各库房以及设备的总体情况、故障情况、单台设备的实时运行情况，实现固定资产的数字化管理，辅助管理人员进行决策；
        </div>
        <div class="fontA11">
          4、绿色节能：平台可以通过设置参数，对仓库及设备状态自动调节，实现按需调节，节约用电成本
        </div>
      </div>
      <div>
        <div class="cpszh">物联网+智慧建造</div>
        <div class="bodyTitle">
          <div class="product">INTELLIGENT BUILDING</div>
          <div class="more">
            <div class="gdal">查看详情</div>
            <div>></div>
          </div>
        </div>
      </div>
      <div class="jsfny11">
        <img class="jsfny1" src="@/assets/jsfni2.png" alt="" />
      </div>
      <div class="fontA1">
        &emsp;&emsp;智慧建造以物联网智能设备监管为核心，制定统一的物联总线标准，采集各智能设备的安全、生产数据，进行实时的监控及管理；系统将逐步沉淀项目施工过程数据，以大数据为基础，云计算及深度学习为手段，实现施工过程中智慧化管理、智慧化生产、智慧化监控、智慧化服务四化目标。
      </div>
      <div class="fontA1">
        &emsp;&emsp;智慧建造是在数字化工地的基础上，利用物联网技术和监控技术加强信息管理服务，提高建造过程可控性、减少人工干预，
        以及合理计划排程。构建高效、节能、绿色、环保、舒适的人性化工地。
      </div>
      <div class="fontA11">
        &emsp;&emsp;系统建设：智慧工地大数据平台是在互联网、大数据时代下，基于物联网、云计算、移动通讯技术，
        围绕建筑施工现场“人、机、料、法、环”五大因素，采用先进技术，为建筑管理、生产、大数据分析提供BIM+项管+智能物联设备的一站式应用解决方案。
      </div>
      <div>
        <div class="cpszh">物联网+大数据采集和分析</div>
        <div class="bodyTitle">
          <div class="product">ACQUISITION AND ANALYSIS</div>
          <div class="more">
            <div class="gdal">查看详情</div>
            <div>></div>
          </div>
        </div>
      </div>
      <div class="jsfny11">
        <img class="jsfny1" src="@/assets/jsfni3.png" alt="" />
      </div>
      <div class="fontA1">平台软件架构技术-模块化</div>
      <div class="fontA1">
        &emsp;&emsp;使用Java开发的完整物联网架构，丰富的接口及组件库，使得开发快速。良好的分层、封装设计，开发都是模块化、面向组件的。模块化是指开发的项目可以打包成单独模块，在其他项目中通过内置接口调用。面向组件是指可以选择适合项目的、各个层级的组件，“搭积木”的方式组合成目标应用。
      </div>
      <div class="jsfny11">
        <img class="jsfny1s" src="@/assets/jsfni4.png" alt="" />
      </div>
      <div class="fontA1">平台数据接入技术-开放化</div>
      <div class="fontA1">
        &emsp;&emsp;具备开放的接入大部分智能设备或者系统的能力，如Modbus、OPC、Lonworks、BACnet、KNX、MBUS等，而且这种能力是开放的，提供标准的接入，可以根据不同的协议自定义开发驱动。
      </div>
      <div class="jsfny11">
        <img class="jsfny2" src="@/assets/jsfni5.png" alt="" />
      </div>
      <div class="fontA1">平台核心技术-边缘计算</div>
      <div class="fontA1">
        &emsp;&emsp;在车间或者现场就可以部署，尽可能接近制造流程的内部实时分析功能，可以改善资产绩效和维护以及改善和优化生产，防止非计划停机。
      </div>
      <div class="jsfny11">
        <img class="jsfny2" src="@/assets/jsfni6.png" alt="" />
      </div>
      <div class="fontA1">平台核心技术-模块化编程</div>
      <div class="fontA1">
        &emsp;&emsp;采用功能块图编程，通过连线就可以实现控制联动的功能，可以在线编程，在线查看控制状态，降低了对现场工程师的技术要求，另外控制算法丰富，并且可以方便地加入所需的特殊控制算法。
      </div>
      <div class="jsfny11">
        <img class="jsfny2s" src="@/assets/jsfni7.png" alt="" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.gdal {
  margin-right: 4px;
  font-size: 14px;
  color: #7d7d7d;
}
.jsfny11 {
  display: flex;
  justify-content: center;
}
.jsfny1 {
  margin: 24px 32px;
}
.jsfny1s {
  margin: 24px 32px 60px 32px;
}
.jsfny2 {
  margin: 16px 32px 60px 32px;
}
.jsfny2s {
  margin: 16px 32px 0 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA1s {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 32px 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 20px 32px;
  line-height: 40px;
}
.fontA2 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 24px 24px 0 32px;
  line-height: 40px;
}
.jsfnr2 {
  margin: 24px 32px 0 32px;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnD1 {
  position: absolute;
  top: 360px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 40px;
  letter-spacing: 1px;
}
</style>
